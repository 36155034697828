<template>
  <section>
    <h2>{{ $t('title') }}</h2>
    <p class="my-3">
      {{ $t('text') }}
    </p>
    <t-btn-link
      id="backToHomepage"
      class="t-btn--outlined"
      to="/"
      color="primary"
    >
      {{ $t('button') }}
    </t-btn-link>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'

export default {
  name: 'LeadConfirmationFormStep',

  extends: AbstractFormStep,
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Je aanvraag is goed afgerond 👍",
    "text": "De eerste stap is afgerond. Nu zijn wij aan zet. Ons team gaat direct voor je aan de slag. Binnen 3 werkdagen nemen onze makelaars contact met je op om je wensen door te nemen en je vrijblijvend een aanbieding te doen.",
    "button": "Terug naar de startpagina"
  },
  "nl-BE": {
    "title": "Uw aanvraag is goed afgerond 👍",
    "text": "De eerste stap is afgerond. Nu is het aan ons. Ons team gaat direct voor u aan de slag. Binnen 3 werkdagen nemen onze immokantoren contact met u op om uw wensen door te nemen en vrijblijvend een aanbieding te doen.",
    "button": "Terug naar de startpagina"
  }
}
</i18n>
